import React from 'react'

import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Foot from '../components/footer'

import '../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'
import { responsive } from '../utils/responsive'

const AboutPage = () => {
    return (<Layout languageSwitchLink="/en/about">
        <SEO title="À propos" />
        <Banner class_suffix="deep" />
        <div className="about-container">
            <div className="about-content">
                <h3>Qui sommes-nous ?</h3>
                <p>
                    PolyCyber est une société technique de Polytechnique Montréal. Depuis 2013, elle œuvre afin de
                    développer l'intérêt et les compétences de notre communauté étudiante pour la cybersécurité.
                </p>
                <h3>Nos missions</h3>
                <ul>
                    <li>Promouvoir les transferts de connaissances</li>
                    <li>Représenter Polytechnique Montréal dans les compétitions de cybersécurité</li>
                    <li>Tisser des liens avec l'industrie</li>
                    <li>Sensibiliser notre communauté aux enjeux liés à la cybersécurité</li>
                </ul>
                <h3>Nos activités</h3>
                <h4>Hacker Nights et aux ateliers techniques</h4>
                <p>
                    Les Hacker Nights sont des événements hebdomadaires où on résout ensemble (ou pas) des défis de
                    cybersécurité. Tous les Mardis de 18h à 21h en L-2708 !
                </p>
                <p>
                    Les ateliers ont pour but d'approfondir des thèmes variés de la cybersécurité, ainsi que d'améliorer
                    les compétences des participant(e)s. Selon les ateliers, le niveau varie de débutant à avancé.
                </p>
                <h4>Compétitions</h4>
                <p>
                    Consultez notre classement sur <a href="https://ctftime.org/team/59057">CTFTime</a>.
                </p>
                <div className="button-group">
                    <a href="https://discord.gg/ZcYnS3GZE6">
                        <button className="signup-button">Rejoins notre Discord</button>
                    </a>
                </div>
                <p>
                    Toute l'année, PolyCyber organise de nombreuses activités telles que des ateliers techniques, des
                    conférences et des compétitions. De plus, nous participons fréquemment à des concours de type
                    Capture the Flag où les participant(e)s doivent résoudre plusieurs défis et trouver une chaîne de
                    caractères (appelée flag) qui atteste de la résolution du défi. Visitez notre blog pour voir les
                    solutionnaires (<i>write-ups</i>) des compétitions auxquelles nous avons participées sur notre
                    <Link to="/blog">blog</Link>.
                </p>
            </div>
        </div>
        <Foot></Foot>
    </Layout>)
}
export default AboutPage
